<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">收藏课程/课件</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabChange">
          <el-tab-pane label="收藏课程" name="10">
            <div class="flexdc flex1" style="height: 100%">
              <div class="operationControl flexdc" style="align-items: flex-start">
                <div class="searchbox">
                  <div title="课程名称" class="searchboxItem">
                    <span class="itemLabel">课程名称:</span>
                    <el-input v-model="searchData.courseName" type="text" size="small" clearable
                      placeholder="请输入课程名称" />
                  </div>
                  <div title="审核状态" class="searchboxItem">
                    <span class="itemLabel">审核状态:</span>
                    <el-select v-model="searchData.auditState" size="small" placeholder="请选择审核状态" clearable>
                      <el-option label="未提审" value="10"></el-option>
                      <el-option label="待审核" value="20"></el-option>
                      <el-option label="已驳回" value="40"></el-option>
                    </el-select>
                  </div>
                  <div title="创建时间" class="searchboxItem">
                    <span class="itemLabel">创建时间:</span>
                    <el-date-picker v-model="searchData.creattime" size="small" type="daterange" range-separator="至"
                      value-format="timestamp" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                  </div>
                  <el-button class="bgc-bv" round @click="getData()" size="small">查询</el-button>
                  <el-button class="bgc-bv" round @click="handleAdd()" size="small">创建课程</el-button>
                </div>
              </div>
              <div class="framePage-scroll">
                <el-table :data="tableData" height="100%" size="small" tooltip-effect="dark" style="width: 100%"
                  :header-cell-style="tableHeader" stripe>
                  <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
                  <el-table-column label="课程名称" align="left" fixed prop="courseName" show-overflow-tooltip
                    min-width="200" />
                  <el-table-column label="总课时" align="left" prop="classHour" show-overflow-tooltip
                    min-width="100" />
                  <el-table-column label="视频时长" align="left" prop="kpointTotalDuration" show-overflow-tooltip min-width="100">
                    <template slot-scope="scope">
                      {{ getTime2(scope.row.kpointTotalDuration) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="课件数量" align="left" prop="kpointTotalNum" show-overflow-tooltip
                    min-width="100" />
                  <el-table-column label="培训类型" align="left" prop="trainTypeNamePath" show-overflow-tooltip
                    min-width="100" />
                  <el-table-column label="职业工种" align="left" prop="occupationNamePath" show-overflow-tooltip
                    min-width="100" />
                  <el-table-column label="培训等级" align="left" prop="trainLevelName" show-overflow-tooltip
                    min-width="100" />
                  <el-table-column label="审核状态" align="left" prop="auditState" show-overflow-tooltip min-width="100">

                    <template slot-scope="scope">
                      {{ $setDictionary('AUDITSTATE', scope.row.auditState) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="备注" align="left" prop="remark" show-overflow-tooltip min-width="100" />
                  <el-table-column label="不通过原因" align="left" prop="auditReason" show-overflow-tooltip min-width="100" />
                  <el-table-column label="操作" width="150px">
                    <div slot-scope="scope">
                      <el-button style="padding: 0 5px" type="text" size="mini" :disabled="!scope.row.enableLook"
                        @click="handleEdit(scope.row)">编辑</el-button>
                      <el-button style="padding: 0 5px" type="text" size="mini"
                        @click="handleDel(scope.row.courseId)">删除</el-button>
                    </div>
                  </el-table-column>
                  <Empty slot="empty" />
                </el-table>
              </div>
              <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="收藏课件" name="20">
            <div class="flexdc flex1" style="height: 100%">
              <div class="operationControl flexdc" style="align-items: flex-start">
                <div class="searchbox">
                  <div title="课件名称" class="searchboxItem">
                    <span class="itemLabel">课件名称:</span>
                    <el-input v-model="searchData.catalogName" type="text" size="small" clearable
                      placeholder="请输入课程名称" />
                  </div>
                  <div title="标签名称" class="searchboxItem">
                    <span class="itemLabel">标签名称:</span>
                    <el-select clearable v-model="searchData.labelId" size="small" filterable remote
                      :remote-method="searchLabel" placeholder="请至少输入两个字搜索">
                      <el-option v-for="item in labelList" :key="item.labelId" :label="item.labelName"
                        :value="item.labelId"></el-option>
                    </el-select>
                  </div>
                  <el-button class="bgc-bv" round @click="getData()" size="small">查询</el-button>
                </div>
              </div>
              <div class="framePage-scroll">
                <el-table :data="tableData" height="100%" size="small" tooltip-effect="dark" style="width: 100%"
                  :header-cell-style="tableHeader" stripe>
                  <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
                  <el-table-column label="课件名称" min-width="260" fixed align="left" show-overflow-tooltip
                    prop="kpointName" />
                  <el-table-column label="标签名称" min-width="100" fixed align="left" show-overflow-tooltip
                    prop="labelName" />
                  <el-table-column label="培训类型" min-width="100" align="left" show-overflow-tooltip
                    prop="trainTypeNamePath" />
                  <el-table-column label="职业/工种" min-width="100" align="left" show-overflow-tooltip
                    prop="occupationNamePath" />
                  <el-table-column label="培训等级" min-width="100" align="left" show-overflow-tooltip
                    prop="trainLevelName" />
                  <el-table-column label="课件类型" min-width="100" align="left" show-overflow-tooltip prop="kpointType">
                    <template slot-scope="scope">
                      {{ $setDictionary('RESOURCETYPE', scope.row.kpointType) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="时长" min-width="100" align="left" show-overflow-tooltip prop="kpointDuration">
                    <template slot-scope="scope">
                      {{ getTime(scope.row.kpointDuration) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="讲师姓名" min-width="100" align="left" show-overflow-tooltip prop="teacherName" />
                  <el-table-column label="资质类型" min-width="100" align="left" show-overflow-tooltip
                    prop="qualificationType" />
                  <el-table-column label="证书编号" min-width="100" align="left" show-overflow-tooltip
                    prop="certificateNo" />
                  <!-- <el-table-column label="审核状态" min-width="100" align="left" show-overflow-tooltip prop="auditState">
                    <template slot-scope="scope">
                      {{ $setDictionary('AUDITSTATE', scope.row.auditState) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="销售状态" min-width="100" align="left" show-overflow-tooltip prop="salesState">
                    <template slot-scope="scope">
                      {{ $setDictionary('SELLSTATE', scope.row.salesState) }}
                    </template>
                  </el-table-column> -->
                  <el-table-column label="操作" width="180px" align="center">
                    <div slot-scope="scope" style="display: flex;align-items: center;justify-content: center;">
                      <uploadAuth watermark innerTable btnshow2
                        :showVideoId="{ kpointVideoId: scope.row.kpointVideoId, kpointName: scope.row.kpointName, kpointSource: scope.row.kpointSource }"
                        :btnshow="false" size="small" />
                      <el-button style="padding: 0 5px" type="text" size="mini"
                        @click="cancelCollect(scope.row.collectId)">取消收藏</el-button>
                    </div>
                  </el-table-column>
                  <Empty slot="empty" />
                </el-table>
              </div>
              <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import uploadAuth from "@/components/uploadAuth";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "collectresourseList",
  components: {
    Empty,
    uploadAuth,
    PageNum,
  },
  mixins: [List],
  doNotInit: true,
  data () {
    return {
      activeName: "10",
      searchData: {
        courseName: '',
        auditState: '',
        creattime: [],
        catalogName: '',
        labelId: '',
      },
      labelList: []
    };
  },
  methods: {
    init () {
      console.log(this.tableHeight);
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      } else {
        this.activeName = "10";
      }
      this.getData();
    },
    getData (pageNum = 1) {
      const { courseName, auditState, creattime, catalogName, labelId } = this.searchData
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      let url
      if (this.activeName == '10') {
        url = '/collect/course/listPage'
        !!courseName && (params.courseName = courseName)
        !!auditState && (params.auditState = auditState)
        if (creattime?.length > 0) {
          params.startTime = creattime[0];
          params.endTime = creattime[1];
        }
      } else if (this.activeName == '20') {
        url = '/collect/collect/listPage'
        !!catalogName && (params.catalogName = catalogName)
        !!labelId && (params.labelId = labelId)
      }
      this.doFetch({
        url,
        params,
        pageNum,
      });
    },
    //取消收藏
    cancelCollect (collectId) {
      this.doDel({
        url: "/collect/collect/cancel",
        msg: "你确定要取消收藏该课件吗？",
        ps: {
          type: "post",
          data: { collectId },
        },
      });
    },
    //删除收藏课程
    handleDel (courseId) {
      this.doDel({
        url: "/collect/course/doDelete",
        msg: "你确定要删除该收藏课程吗？",
        ps: {
          type: "post",
          data: { courseId },
        },
      });
    },
    // 创建课程
    handleAdd () {
      this.$router.push({ path: "/web/collectresourseList/Createcourse" });
    },
    // 编辑课程
    handleEdit (row) {
      this.$router.push({
        path: "/web/collectresourseList/Createcourse",
        query: {
          courseId: row.courseId,
          courseOrigin: row.courseOrigin,
        },
      });
    },
    getTime (val) {
      let m = parseInt(val / 60);
      let s = val % 60;
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return m + ":" + s;
    },
    // 时间处理 - 时分秒
    getTime2 (val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
        (m != "00" ? m + "分" : "") +
        (s != "00" ? s + "秒" : "") || ""
      );
    },
    tabChange () {
      this.getData(1);
    },
    searchLabel (query) {
      this.$post("/collect/label/queryList", { labelName: query }).then(
        (res) => {
          this.labelList = res.data.list;
        }
      );
    },
    beforeTabLeave (newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if (from.path == '/web/collectresourseList/Createcourse') {
        vm.getData(vm.pageNum)
      }
    })
  }
};
</script>

<style lang="less" scoped></style>
