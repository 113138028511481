<template>
  <div class="upload">
    <el-upload
      class="uploadvideo"
      action=""
      :accept="allowFileType"
      :on-change="handleChange"
      :file-list="fileList"
      :auto-upload="false"
      :show-file-list="false"
    >
      <el-button v-show="btnshow" slot="trigger" size="mini">上传本地视频</el-button>
      <el-button
        :class="innerTable?'':'bgc-bv'"
        size="mini"
        :type="innerTable?'text':''"
        v-if="showVideoId.kpointVideoId"
        :disabled="!btnshow2"
        @click.stop="() => showVideo(showVideoId)"
        >预览</el-button
      >
      <!--
      <el-button style="margin-left: 10px;width: 170px" @click="authUpload"
        >开始上传</el-button
      >
      -->
      <!--      <el-button style="margin-left: 10px;" type="success" @click="pauseUpload">暂停</el-button>
      <el-button style="margin-left: 10px;" type="success" @click="resumeUpload">恢复上传</el-button>-->
      <div slot="tip" class="el-upload__tip" v-if="showProgress">
        <!-- <span>上传状态：{{ statusText }}</span> -->
        <el-progress
          :color="customColor"
          :text-inside="true"
          :stroke-width="26"
          :percentage="authProgress"
          style="width: 350px"
        ></el-progress>
      </div>
    </el-upload>
    <el-dialog
      v-if = "dialogVisible1"
      :title="videotitle"
      :modal="false"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2 :videoId="videoId" :videoTime="videoTime" :kpointSource="kpointSource" v-if="videoType"/>
      <player_1 :watermark="watermark" :videoId="videoId" :videoTime="videoTime" :kpointSource="kpointSource" v-else/>
    </el-dialog>
  </div>
</template>
<script>
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../utils/swigger";
export default {
  props: {
    showVideoId: Object,
    btnshow:Boolean,
    btnshow2:Boolean,
    watermark: {//是否开启水印
      type: Boolean,
      default: false
    },
    innerTable: {//是否在列表中
      type: Boolean,
      default: false
    },
  },
  components: {
      player_1,
      player_2
  },
  data() {
    return {
      //配置参数
      timeout: "6000000",
      partSize: "1048576",
      parallel: "5",
      retryCount: "3",
      retryDuration: "2",
      region: "cn-shanghai",
      userId: "1192632080203852",
      //上传对象
      fileList: [],
      allowFileType: ".mp4,.MP4",
      //组件对象
      uploader: null,
      authProgress: 0,
      uploadState: "1",
      statusText: "",
      customColor: "#5C6BE8",
      showProgress: false,
        videoId:"", // 視頻id
        videoType: null, // true-可以看；false-试看
        dialogVisible1:false,
        videotitle:"",
        videoTime:"",
        status: 1
    };
  },
    created() {

    },
    methods: {
      handleClose(){
          this.dialogVisible1 = false,
              this.status = 3;
              distorySwigger()
      },
    checkReg(val, regName) {
      let reg = {
        userName: /^[a-zA-Z0-9_]{2,16}$/,
        password: /^[A-Za-z0-9]+$/,
        fullName: /^[\u4e00-\u9fa5]+$/,
        caseAndNumber: /^[A-Za-z0-9]+$/,
        code: /^[A-Za-z0-9_-]+$/,
        phone: /^((0\d{2,3}-\d{7,8})|(0\d{9,11})|(1\d{10})|(\d{7,8}))$/,
        mobile: /^1\d{10}$/,
        fileName: new RegExp('[\\\\/:*?"<>|]'),
        eMall: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      };
      return reg[regName].test(val);
    },
    /**
     * 校验文件
     * @param file
     */
    beforeUpload(file) {
      let that = this;
      //文件名不能超长,不能还有特殊字符
      let fileName = file.name;
      if (fileName.length > 100) {
        that.$message.warning({
          message: "资源文件名称不能大于100个字符！",
          duration: 1000
        });
        return false;
      }
      if (that.checkReg(fileName, "fileName")) {
        that.$message.warning({
          message: "资源文件名称不能包含特殊字符！",
          duration: 1000
        });
        return false;
      }
      //校验文件类型，是否允许上传
      let fileType = file.name.split(".");
      if (!that.allowFileType.includes(fileType[fileType.length - 1])) {
        that.$message.warning({
          message: "只能上传.MP4格式的文件！",
          duration: 1000
        });
        return false;
      }
      return true;
    },
    /**
     * 文件列表改变回调
     * @param file
     * @param fileList
     */
    handleChange(file, fileList) {
      //校验文件
      if (this.beforeUpload(file)) {
        //添加视频
        if (this.uploader) {
          this.uploader.stopUpload();
          this.authProgress = 0;
          this.statusText = "";
        }
        this.uploader = this.createUploader();
        this.uploader.addFile(file.raw, null, null, null, '{"Vod":{}}');
        this.uploadState = "1";
        //删除之前的文件。
        this.fileList = fileList.slice(-1);
        //修改为验证文件通过后直接开始上传的模式
        this.showProgress = true;
        this.authUpload();
      } else {
        this.fileList = [];
      }
    },
    /**
     * 开始上传
     * @param
     */
    authUpload() {
      // 然后调用 startUpload 方法, 开始上传
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.uploadState = "2";
      } else {
        this.$message("您还未选择上传视频！");
      }
    },
    /**
     * 暂停上传
     * @param
     */
    pauseUpload() {
      if (this.uploader !== null) {
        this.uploader.stopUpload();
        this.uploadState = "3";
      }
    },
    /**
     * 恢复上传
     * @param
     */
    resumeUpload() {
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.uploadState = "2";
      }
    },
    sendVideoId(videoId) {
      //func: 是父组件指定的传数据绑定的函数，this.msg:子组件给父组件传递的数据
      this.showVideoId = "";
      this.$emit("childFn", videoId);
    },
    /**
     * 生产上传对象
     * @param
     */
    createUploader() {
      let self = this;
      let uploader = new AliyunUpload.Vod({
        timeout: self.timeout,
        partSize: self.partSize,
        parallel: self.parallel,
        retryCount: self.retryCount,
        retryDuration: self.retryDuration,
        region: self.region,
        userId: self.userId,
        // 添加文件成功
        addFileSuccess: function() {
          self.statusText = "添加文件成功, 等待上传...";
        },
        // 开始上传
        onUploadstarted: function(uploadInfo) {
          // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
          // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
          // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
          // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
          // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
          // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
          let info = {
            title: uploadInfo.file.name,
            filename: uploadInfo.file.name
          };
          // 如果videoId没有值，根据videoId刷新上传凭证
          self
            .$post("/sys/video/createUploadVideo", info)
            .then(result => {
              if (result.status === "0") {
                let uploadAuth = result.data.uploadAuth;
                let uploadAddress = result.data.uploadAddress;
                let videoId = result.data.videoId;
                uploader.setUploadAuthAndAddress(
                  uploadInfo,
                  uploadAuth,
                  uploadAddress,
                  videoId
                );
                self.statusText = "文件开始上传...";
              } else {
                self.$message.warning({ message: result.msg, duration: 1500 });
              }
            })
            .catch(err => {
              self.$message.error(err.message);
            });

          /*if (!uploadInfo.videoId) {
              let info = {
                title : uploadInfo.file.name,
                fileName : uploadInfo.file.name
              };
              // 如果videoId没有值，根据videoId刷新上传凭证
              self.post('commonController/createUploadVideo.do',info).then(result =>{
                if (result.success){
                  let uploadAuth = result.info.uploadAuth;
                  let uploadAddress = result.info.uploadAddress;
                  let videoId = result.info.videoId;
                  uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId);
                  self.statusText = '文件开始上传...';
                } else {
                  self.$message.warning({message:result.msg,duration : 1500});
                }
              }).catch(err =>{
                self.$message.error(err.message);
              });
            } else {
              // 如果videoId有值，根据videoId刷新上传凭证
              // https://help.aliyun.com/document_detail/55408.html?spm=a2c4g.11186623.6.630.BoYYcY
              let refreshUrl = 'https://demo-vod.cn-shanghai.aliyuncs.com/voddemo/RefreshUploadVideo?BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=59ECA-4193-4695-94DD-7E1247288&AppVersion=1.0.0&Title=haha1&FileName=xxx.mp4&VideoId=' + uploadInfo.videoId
              axios.get(refreshUrl).then(({data}) => {
                let uploadAuth = data.UploadAuth
                let uploadAddress = data.UploadAddress
                let videoId = data.VideoId
                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
              })
            }*/
        },
        //文件上传成功
        onUploadSucceed: function(uploadInfo) {
          self.sendVideoId(uploadInfo.videoId);
          self.statusText = "文件上传成功!";
        },
        // 文件上传失败
        onUploadFailed: function(uploadInfo, code, message) {
          console.log(message);
          self.statusText = "文件上传失败!";
        },
        // 取消文件上传
        onUploadCanceled: function(uploadInfo, code, message) {
          console.log(message);
          self.statusText = "文件已暂停上传";
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function(uploadInfo, totalSize, progress) {
            let _this = this
          let progressPercent = Math.ceil(progress * 100);
          self.authProgress = progressPercent;
          self.statusText = "文件上传中...";
        },
        // 上传凭证超时
        onUploadTokenExpired: function(uploadInfo) {
          // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
          // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
          // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
          let refreshUrl =
            "https://demo-vod.cn-shanghai.aliyuncs.com/voddemo/RefreshUploadVideo?BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=59ECA-4193-4695-94DD-7E1247288&AppVersion=1.0.0&Title=haha1&FileName=xxx.mp4&VideoId=" +
            uploadInfo.videoId;
          this.$get(refreshUrl).then(({ data }) => {
            let uploadAuth = data.UploadAuth;
            uploader.resumeUploadWithAuth(uploadAuth);
            console.log(
              "upload expired and resume upload with uploadauth " + uploadAuth
            );
          });
          self.statusText = "文件超时...";
        },
        // 全部文件上传结束
        onUploadEnd: function(uploadInfo) {
          console.log(uploadInfo);
          self.statusText = "文件上传完毕";
        }
      });
      return uploader;
    },
      // 预览
      showVideo(data) {
        let _this = this
          console.log(data);
          //单元测试打开是题库
          if (data.kpointVideoId) {
              _this
                  .$post("/biz/courseware/auth", {videoId: data.kpointVideoId})
                  .then(result => {
                      _this.videoId = data.kpointVideoId;
                      console.log(_this.videoId);
                      _this.videoType = result.data.trySee;
                      console.log(_this.videoType );
                      _this.videoTime = result.data.duration;
                      _this.videotitle = data.kpointName;
                      _this.kpointSource = data.kpointSource;
                      _this.dialogVisible1 = true;
                  })
          }
      },
  }
};
</script>
<style lang="less">
.upload {
  .uploadvideo {
    display: flex;
    .el-upload {
      height: auto !important;
      border: none;
    }
  }
  .el-button {
    height: auto !important;
  }
}
</style>
